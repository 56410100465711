<div bsModal #holdMedModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="holdMedModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #holdMedForm="ngForm" novalidate (ngSubmit)="HoldMedication()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-uppercase  text-primary font-weight-bolder">
                            {{drugName}}
                        </h4>

                        <h4 class="modal-title text-uppercase font-weight-bolder">
                            {{patientName}}
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close mt-n10" [disabled]="saving" title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body mt-n7">
                    <tabset #tabSet>
                        <tab id="firstTab" (selectTab)="onTabChange('firstTab')">
                            <ng-template tabHeading>
                                <span><i class="fas fa-pause pr-2"></i>Hold Med</span>
                            </ng-template>

                            <div class="card-body pt-5 p-0">
                                <div class="row col-lg-12">
                                    <div class="form-group col-md-12 col-sm-6">
                                        <label>Hold Reason</label>
                                        <div class="radio-inline">
                                            <label
                                                class="btn btn-outline-secondary radio radio-outline radio-info ml-1">
                                                <input type="radio" name="radios15" id="radios1" value="1"
                                                    [(ngModel)]="holdMedication.holdReason">
                                                <span></span>Abnormal Readings
                                            </label>
                                            <label class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                <input type="radio" name="radios15" id="radios2"
                                                    [(ngModel)]="holdMedication.holdReason" value="2">
                                                <span></span>Waiting for Procedure
                                            </label>
                                            <label class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                <input type="radio" name="radios15" id="radios3"
                                                    [(ngModel)]="holdMedication.holdReason" value="3">
                                                <span></span>Physician's order
                                            </label>
                                            <label class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                <input type="radio" name="radios15" id="radios4"
                                                    [(ngModel)]="holdMedication.holdReason" value="4">
                                                <span></span>Other
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6">
                                        <label>Hold From<span class="text-danger">*</span></label>
                                        <input type="date" name="holdForm" id="holdForm" (change)="validateDates()"
                                            [(ngModel)]="holdMedication.holdFrom" class="form-control" />
                                        <span class="text-danger"
                                            *ngIf="(_required==true) && (holdMedication.holdFrom==null ||holdMedication.holdFrom==undefined)">Required</span>
                                    </div>



                                    <div class="form-group col-md-6 col-sm-6">
                                        <label>Resume From<span class="text-danger">*</span></label>
                                        <input type="date" name="resumeForm" id="resumeForm" (change)="validateDates()"
                                            [(ngModel)]="holdMedication.resumeForm" class="form-control" />
                                        <span class="text-danger"
                                            *ngIf="(_required==true) && (holdMedication.resumeForm==null ||holdMedication.resumeForm==undefined )">Required</span>
                                        <span class="text-danger" *ngIf="dateValidationError">Resume From must be later
                                            than Hold From</span>
                                    </div>



                                    <div class="form-group col-md-6 col-sm-6">
                                        <label>Nurse Notified?</label>

                                        <div class="d-flex align-items-center">
                                            <!-- Use flex to align items -->
                                            <span class="input-group-text">
                                                <label class="checkbox checkbox-danger checkbox-outline">
                                                    <input type="checkbox" name="Checkboxes17" id="Checkboxes17"
                                                        [(ngModel)]="holdMedication.nurseNotified"
                                                        (change)="nurseNotifiedChecked($event)">
                                                    <span></span>Yes
                                                </label>
                                            </span>
                                            <!-- Autocomplete input -->
                                            <div class="ml-2 flex-grow-1">
                                                <p-autoComplete [(ngModel)]="this.SelectedNurseName"
                                                    class="fieldLengthCss"
                                                    [disabled]="holdMedication.nurseNotified != true"
                                                    id="ClientInfo_Client" [type]="'search'" [suggestions]="clientData"
                                                    (input)="searchNurse($event)" [minLength]="1"
                                                    (onSelect)="onSelect($event)" placeholder="Search Nurse..."
                                                    inputStyleClass="form-control" class="zindex-unset"
                                                    (ngModelChange)="onNurseNameChange($event)" name="nursNotified">
                                                    <ng-template let-clients pTemplate="item">
                                                        <div class="d-flex align-items-center mb-2 p-2 alternative_cls">
                                                            <div
                                                                class="d-flex flex-column flex-grow-1 font-weight-bold">
                                                                <a
                                                                    class="text-dark text-hover-primary mb-1">{{clients.userName}}</a>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                                <span class="text-danger"
                                                    *ngIf="(_required==true) && (holdMedication.nurseNotified) && (holdMedication.nurseNotifiedId==null ||holdMedication.nurseNotifiedId==undefined || holdMedication.nurseNotifiedId=='')">Nurse
                                                    selection is mandatory when "Nurse Notified" is checked.</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="form-group col-md-6 col-sm-6">
                                        <label>Care Team Notified?</label>
                                        <div class="flex-nowrap input-group">
                                            <span class="input-group-hover input-group-text">
                                                <label class="checkbox checkbox-danger checkbox-outline">
                                                    <input type="checkbox" name="Checkboxes12" id="checkboxes13"
                                                        [(ngModel)]="holdMedication.careTeamNotified"
                                                        (change)="careTeamChecked($event)">
                                                    <span></span>Yes
                                                </label>
                                            </span>
                                            <div class="ml-1">
                                                <input type="text" class="form-control" name="CareTeamNotified"
                                                    id="careTeamNotified" [(ngModel)]="holdMedication.careTeamId"
                                                    [disabled]="holdMedication.careTeamNotified!=true"
                                                    placeholder="Enter Group Name">
                                                <span class="text-danger"
                                                    *ngIf="(_required==true) && (holdMedication.careTeamNotified) && (holdMedication.careTeamId==null || holdMedication.careTeamId==undefined || holdMedication.careTeamId=='')">Group
                                                    Name is mandatory when "Care Team Notified" is checked.</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group col-sm-12">
                                        <label>Comments (required)<span class="text-danger">*</span></label>
                                        <textarea class="form-control" id="CommentsTextarea" name="commentsTextarea"
                                            [(ngModel)]="holdMedication.comment" rows="2"
                                            placeholder="Enter your comments here"></textarea>
                                        <span class="text-danger"
                                            *ngIf="(_required==true) && (holdMedication.comment==null ||holdMedication.comment==undefined)">Required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row card card-custom pb-0 bg-light">
                                <div class="card-body pt-3">
                                    <!-- Title Section -->
                                    <h4 class="card-title mb-4 text-center text-primary"
                                        style="font-weight: bold; background-color: #f5f8fa; padding: 10px; border-radius: 5px;">
                                        Resume Hold Medication History
                                    </h4>

                                    <!-- Scrollable Table Section -->
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <div
                                                style="max-height: 400px; overflow-y: auto; background-color: #ffffff; border-radius: 5px; border: 1px solid #ddd;">
                                                <!-- Apply Metronic's table classes -->
                                                <table class="table table-hover table-striped table-borderless">
                                                    <thead class="bg-light-primary text-dark"
                                                        style="position: sticky; top: 0; z-index: 10;">
                                                        <tr>
                                                            <th scope="col">Sr No</th>
                                                            <th scope="col">Hold Reason</th>
                                                            <th scope="col">Hold Date/Resume Date</th>
                                                            <th scope="col">Nurse Notified</th>
                                                            <th scope="col">Care Team Notified</th>
                                                            <th scope="col">Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of resumedMedications; let i = index;">
                                                            <th>{{ i + 1 }}</th>
                                                            <th scope="row">{{ item.holdReasonString }}</th>
                                                            <th>{{ item.holdFrom | date: 'MM/dd/yyyy' }} - {{
                                                                item.resumeForm | date: 'MM/dd/yyyy'
                                                                }}</th>
                                                            <th>{{ item.nurseNotifiedId }}</th>
                                                            <th>{{ item.careTeamId }}</th>
                                                            <th>{{ item.comment }}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </tab>
                        <tab id="secondTab" (selectTab)="onTabChange('secondTab')">
                            <ng-template tabHeading>
                                <span><i class="fas fa-pause pr-2"></i>Hold Schedule</span>
                            </ng-template>

                            <div class="card-body pt-5 p-0">
                                <div class="row col-lg-12">


                                    <div class="form-group col-md-12 col-sm-12">
                                        <table class="table mat-table-sticky table-hover table-users">
                                            <thead class="thcolorCss">
                                                <tr class="m-datatable__row tableheaderColorCss">
                                                    <th data-field="phone"
                                                        class="m-datatable__cell m-datatable__cell--sort ">
                                                        <span>Schedule</span>
                                                    </th>

                                                    <!-- <th data-field="ratio" 
                                                            class="m-datatable__cell m-datatable__cell--sort widthratio">
                                                            <span> Hold From</span>
                                                        </th>

                                                        <th class="m-datatable__cell m-datatable__cell--sort">
                                                           Resume From</th> -->

                                                    <th class="m-datatable__cell m-datatable__cell--sort w-25">
                                                        Action</th>


                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let hc of medSchTime;let i = index">
                                                    <td class="bit2-boldLabel"><span> Dose at {{hc.scheduleTime}}</span>
                                                    </td>
                                                    <!-- <td class="bit2-boldLabel"><span>{{hc.holdScheduleTime.holdFrom | date:"MM/dd/yyyy"}}</span>
                                                           

                                                        </td>
                                                        <td class="outcometextareaCss"><span>{{hc.holdScheduleTime.resumeFrom | date:"MM/dd/yyyy"}}</span>
                                                            
                                                        </td> -->
                                                    <td class="pl-0">
                                                        <button type="button"
                                                            [disabled]="holdMedication.id || status == 'Hold'"
                                                            (click)="AddHold(hc,hc.holdScheduleTime)"
                                                            class="btn btn-light-primary font-weight-bold ">Add New
                                                            Hold</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                            </div>

                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <h4>Hold History</h4>
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <div style="max-height: 400px; overflow-y: auto;">
                                                <table class="table table-hover cursor-pointer">
                                                    <thead
                                                        style="position: sticky; top: 0; background-color: white; z-index: 10;">
                                                        <tr>
                                                            <th scope="col">Schedule</th>
                                                            <th scope="col">Hold Date/Resume Date</th>
                                                            <th scope="col">Hold Reason</th>
                                                            <th scope="col">Action</th>


                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                        <tr *ngFor="let item of holdSchHistory;let i=index;">
                                                            <th scope="row">{{item.scheduleTime}}</th>
                                                            <th>{{item.holdScheduleTime.holdFrom | date:"MM/dd/yyyy"}} -
                                                                {{item.holdScheduleTime.resumeFrom | date:"MM/dd/yyyy"}}
                                                            </th>
                                                            <th>{{item.holdScheduleTime.holdReasonString}}</th>
                                                            <td>
                                                                <button [disabled]="holdMedication.id"
                                                                    *ngIf="item.holdScheduleTime.status=='Resume'"
                                                                    type="button"
                                                                    (click)="View(item,item.holdScheduleTime)"
                                                                    class="btn btn-light-primary font-weight-bold">View</button>
                                                                <button
                                                                    [disabled]="holdMedication.id || status == 'Hold'"
                                                                    *ngIf="item.holdScheduleTime.status=='Hold'"
                                                                    (click)="editHoldSchdl(item,item.holdScheduleTime)"
                                                                    type="button"
                                                                    class="btn btn-light-warning font-weight-bold">Edit
                                                                </button>
                                                                <button [disabled]="holdMedication.id" type="button"
                                                                    *ngIf="item.holdScheduleTime.status=='Hold'"
                                                                    (click)="holdSchdlDialog(item,item.holdScheduleTime)"
                                                                    class="btn btn-light-warning font-weight-bold">Resume</button>

                                                            </td>
                                                        </tr>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <holdScheduleModalDialog #holdScheduleModalDialog (saveHoldSchedulMed)="getHoldSchedulMed($event)">
                </holdScheduleModalDialog>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">

                            <button *ngIf="activeTab === 'firstTab' && status == 'Hold'" [disabled]="saving"
                                type="button" class="btn btn-light-warning font-weight-bold mr-2"
                                (click)="resumeHoldMedication()">
                                {{"Resume" | localize }}
                            </button>
                            <button [disabled]="saving" *ngIf="activeTab === 'firstTab' && status != 'Hold'"
                                type="button" (click)="HoldMedication()"
                                class="btn btn-light-primary font-weight-bold">Save</button>
                            <button [disabled]="saving" *ngIf="activeTab === 'firstTab' && status =='Hold'"
                                type="button" (click)="HoldMedication()"
                                class="btn btn-light-primary font-weight-bold">Update</button>
                            <button [disabled]="saving" type="button" class="btn btn-light-danger font-weight-bold mr-2"
                                (click)="close()">
                                {{"Close" | localize }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>