<div bsModal #addErrorModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addErrorModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #addErrorForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-primary font-weight-bolder">
                            {{patientsName}}
                        </h4>

                        <h4 class="modal-title font-weight-bolder">
                            {{medicationName}}
                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close mt-n10" [disabled]="saving" title="Close">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body mt-n7">
                    <tabset>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-exclamation-circle pr-2"></i>Add Error</span>
                            </ng-template>
                            <div class="row card card-custom">
                                <div class="card-body pt-5 pb-0">
                                    <div class="row">
                                        <div class="form-group col-md-12 col-sm-6">
                                            <label>Error Type</label>
                                            <div class="radio-inline">
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-info ml-1">
                                                    <input type="radio" name="radios15" id="Wrong" value="1"
                                                        [(ngModel)]="medicationError.errorType" checked="checked">
                                                    <span></span>Wrong Person
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" id="Incorrect" value="2"
                                                        [(ngModel)]="medicationError.errorType" name="radios15">
                                                    <span></span>Incorrect Medicine
                                                </label>

                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" id="Dose" value="3"
                                                        [(ngModel)]="medicationError.errorType" name="radios15">
                                                    <span></span>Incorrect Dose
                                                </label>

                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" id="Time" value="4"
                                                        [(ngModel)]="medicationError.errorType" name="radios15">
                                                    <span></span>Incorrect Time
                                                </label>

                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" id="instructions" value="5"
                                                        [(ngModel)]="medicationError.errorType" name="radios15">
                                                    <span></span>Did not follow instructions
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" id="Other" value="6"
                                                        [(ngModel)]="medicationError.errorType" name="radios15">
                                                    <span></span>Other
                                                </label>
                                            </div>
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (medicationError.errorType==null ||medicationError.errorType==undefined )">Required</span>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6">
                                            <label>Nurse Notified?</label>
                                            <div class="d-flex align-items-center">
                                                <span class="input-group-hover input-group-text">
                                                    <label class="checkbox checkbox-danger checkbox-outline">
                                                        <input type="checkbox"
                                                            [(ngModel)]="medicationError.nurseNotified"
                                                            id="nurseNotifie" name="Checkboxes12"
                                                            (change)="nurseNotifiedChecked($event)">
                                                        <span></span>Yes
                                                    </label>
                                                </span>
                                                <div class="ml-1">
                                                    <!-- <input type="text" [(ngModel)]="medicationError.nurseNotifiedId" id="nurseNotified" name="nursenotified" class="form-control" placeholder="Enter Nurse Name"> -->
                                                    <p-autoComplete [(ngModel)]="selectclientName"
                                                        class="fieldLengthCss" id="ClientInfo_Client"
                                                        [disabled]="medicationError.nurseNotified!=true"
                                                        [type]="'search'" [suggestions]="clientData"
                                                        (input)="searchClient($event)" [minLength]="1"
                                                        (onSelect)="onSelect($event)" placeholder="Search Nurse..."
                                                        (ngModelChange)="onNurseNameChange($event)"
                                                        inputStyleClass="form-control " [style]="{'width':'100%'}"
                                                        name="ClientInfo_Client">
                                                        <ng-template let-clients pTemplate="item">
                                                            <div
                                                                class="d-flex align-items-center mb-2 p-2 alternative_cls">
                                                                <div
                                                                    class="d-flex flex-column flex-grow-1 font-weight-bold">
                                                                    <a
                                                                        class="text-dark text-hover-primary mb-1 ">{{clients.userName}}</a>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-autoComplete>
                                                    <div *ngIf="medicationError.nurseNotified && !medicationError.nurseNotifiedId"
                                                        class="text-danger">
                                                        Please select a nurse.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 col-sm-6">
                                            <label>Care Team Notified?</label>
                                            <div class="flex-nowrap input-group">
                                                <span class="input-group-hover input-group-text">
                                                    <label class="checkbox checkbox-danger checkbox-outline">
                                                        <input type="checkbox"
                                                            [(ngModel)]="medicationError.careTeamNotified" id="careTeam"
                                                            name="Checkboxes13">
                                                        <span></span>Yes
                                                    </label>
                                                </span>
                                                <div class="ml-1">
                                                    <input type="text" [(ngModel)]="medicationError.careTeamId"
                                                        id="careTeamNotifie" name="Checkboxes1" class="form-control"
                                                        placeholder="Enter Group Name">
                                                    <div *ngIf="medicationError.careTeamNotified && !medicationError.careTeamId"
                                                        class="text-danger">
                                                        Please enter a care team group name.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group col col-form-label">
                                            <label
                                                class="btn btn-outline-secondary checkbox checkbox-outline checkbox-danger">
                                                <input type="checkbox" [(ngModel)]="medicationError" id="reminder"
                                                    name="Checkboxes15">
                                                <span></span>Remind me to create an Incident Report
                                            </label>
                                        </div>
                                        <div class="form-group col-sm-12">
                                            <label>Comments</label>
                                            <textarea class="form-control" [(ngModel)]="medicationError.comment"
                                                name="comment" id="CommentsTextarea" rows="2"
                                                placeholder="Enter your comments here"></textarea>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_required==true) && (medicationError.comment==null ||medicationError.comment==undefined )">Required</span>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-history pr-2"></i>History</span>
                            </ng-template>

                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <table class="table table-hover cursor-pointer">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date Time</th>
                                                        <th scope="col">Error Type</th>
                                                        <th scope="col">Nurse Notified</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let oMiObj of errorHistory">
                                                        <th scope="row">{{oMiObj.creationTime | date:'short'}}</th>
                                                        <td>{{oMiObj.errorType == 1? 'Wrong Person': oMiObj.errorType ==
                                                            2 ? 'Incorrect Medicine' : oMiObj.errorType == 3 ?
                                                            'Incorrect Dose' : oMiObj.errorType == 4 ? 'Incorrect Time'
                                                            : oMiObj.errorType == 5 ? 'Did not follow instructions' :
                                                            'Other' }}</td>
                                                        <td>{{oMiObj.nurseNotified== 'true'?'Yes':'No'}}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <th scope="row">12/12/21 09:00 PM</th>
                                                        <td>Incorrect Time</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12/12/21 10:10 AM</th>
                                                        <td>Incorrect Dose</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12/12/21 6:00 AM</th>
                                                        <td>Other</td>
                                                        <td>Yes</td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button"
                                class="btn btn-light-primary font-weight-bold mr-2" (click)="close()">
                                {{
                                "Cancel" |
                                localize
                                }}
                            </button>
                            <button type="submit" class="btn btn-primary font-weight-bold"
                                [disabled]="!addErrorForm.form.valid">
                                <i class="fas fa-check pr-2"></i>
                                <span>{{"Save" | localize}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>