import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Inject, Input, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { ClientMedicationsServiceProxy, GetDrug_MasterForViewDto, DiscontinuedMedsServiceProxy, CreateOrEditDiscontinuedMedDto, GetDiscontinuedMedForEditOutput, CreateOrEditClientMedicationDto, CreateOrEditDiscontinuedMedDocUploadDto, DiscontinuedMedDocUploadsServiceProxy, CreateOrEditClient_Medication_Schedule_TimeDto, } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent, Paginator, Table } from 'primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { finalize } from 'rxjs/operators';
import { result } from 'lodash';
import { createOrEditpcbModalComponent } from '@app/pcbbp/pcBs/create-or-edit-pcb.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location as backLocation } from '@angular/common';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
    selector: 'discontinuedMedDialog',
    templateUrl: './discontinuedMedDialog.component.html',
    styleUrls: ['discontinuedMedDialog.component.css']
})
export class DiscontinuedMedDialogComponent extends AppComponentBase {

    //saveDiscontinoue
    @Output() saveDiscontinoue: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('discontinuedMedd', { static: true }) modal: ModalDirective;
    @ViewChild('medScheduleForm', { static: true }) form: NgForm;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @Output() sendToAddMedication: EventEmitter<any> = new EventEmitter<any>();
    active = false;
    saving = false;
    filterText = '';
    drugDescriptionFilter = '';
    controlledSubstanceFilter = -1;
    activeFilter = -1;
    drugnameFilter = '';
    drugcodeFilter = '';
    categoryFilter = '';
    drug_Category_LookUpNameFilter = '';
    drug_DoseForm_LookUpNameFilter = '';
    drug_DrugCodingSystem_LookUpNameFilter = '';
    drug_MedicationType_LookUpNameFilter = '';
    drug_Routes_LookUpNameFilter = '';
    drug_strengthUnit_LookUpNameFilter = '';
    drug_SubCategory_LookUpNameFilter = '';
    record: GetDrug_MasterForViewDto[];
    DrugIfo: GetDrug_MasterForViewDto[];
    Description: string;
    documentId;
    clientId;
    docId = 0;
    editmode = false;
    isnewdocuploadded = false;
    documenttype: number;
    discontinuedMedId: any;
    status: string;
    str = " ";
    meddata: any;
    _Parentmode: any;
    @Input() filter: any;
    allReasons: any;
    selectReasons
    private sub: any;
    _view = false;
    _mode: string;
    drugName: string;
    patientName: string;
    medicationId
    genderName: string;
    dob;
    _required = false;
    clientMedication: CreateOrEditClientMedicationDto = new CreateOrEditClientMedicationDto();
    medSchTime: CreateOrEditClient_Medication_Schedule_TimeDto[];
    noDocument = false;
    docList: any;
    discontinuedocument: CreateOrEditDiscontinuedMedDocUploadDto = new CreateOrEditDiscontinuedMedDocUploadDto();
    selecteddocs: CreateOrEditDiscontinuedMedDocUploadDto[] = new Array();
    discontinuedocDto: CreateOrEditDiscontinuedMedDocUploadDto[] = new Array();
    //  selecteddocs: CreateOrEditDiscontinuedMedDto[] = new Array();
    discontinueMedication: CreateOrEditDiscontinuedMedDto = new CreateOrEditDiscontinuedMedDto();
    discMed: GetDiscontinuedMedForEditOutput = new GetDiscontinuedMedForEditOutput();
    // public uploader: FileUploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/AzureBlob/MedicationUploadDocument', headers: [{ name: 'Authorization', value: 'Bearer ' + abp.auth.getToken() }] });
    public uploader: FileUploader = new FileUploader({
        url: AppConsts.remoteServiceBaseUrl + '/Medication/MedicationUploadDocument',
        authToken: 'Bearer ' + abp.auth.getToken(),
        autoUpload: false,
        disableMultipart: false

    });
    constructor(injector: Injector,
        public dialogRef: MatDialogRef<DiscontinuedMedDialogComponent>,
        private _discontinuedMedServiceProxy: DiscontinuedMedsServiceProxy,
        private _activatedRoute: ActivatedRoute,
        private _router: Router, private location: backLocation,
        private _clientMedicationServiceProxy: ClientMedicationsServiceProxy,
        private _DiscontinuedMedDocUploadsServiceProxy: DiscontinuedMedDocUploadsServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: any,) {
        super(injector);

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);


    }

    ngOnInit(): void {
    }

    getDrug() {

        this.Description = this.filter;
        this._clientMedicationServiceProxy.getAllDrugDetails(this.str
        ).subscribe(result => {
            this.record = result;
            this.DrugIfo = result;
            this.searchDescription();
        });

    }

    searchDescription() {
        if (this.Description) {
            this.Description = this.Description.trim();

            if (this.Description) {
                this.record = this.DrugIfo.filter(s => s.drug_Master.drugDescription.replace(' ', '').toLowerCase().includes(this.Description.replace(' ', '').toLowerCase()));
            }
            else {
                this.record = this.DrugIfo;
            }
        }
        else {
            this.record = this.DrugIfo;
        }
    }


    onDrugSelect(drugMasterId?: number): void {
        //this._router.navigate(['/app/medications/addMedication/add-medication', drugMasterId, "edit", 0]);
        this.sendToAddMedication.emit(drugMasterId);
        this.modal.hide();
    }


    counter(i: number): any {
        return new Array(i);
    }
    calculateage(dob) {
        if (dob) {
            var today = new Date();
            var birthDate = moment(dob).toDate();
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return ''
        }
    }

    show(clientMedicationId?: number): void {
        this.meddata
        this.drugName = this.meddata.drugDescription;
        this.patientName = this.meddata.patientsname;
        this.dob = this.meddata.dob;
        this.genderName = this.meddata.gender;
        this._discontinuedMedServiceProxy.getClient_Medication_ScheduleTime_Disc(clientMedicationId).subscribe(result => {
            this.medSchTime = result;
        })

        if (this.status == 'Approved') {
            this._view = false;
            this.discontinueMedication = new CreateOrEditDiscontinuedMedDto();
            this.discontinueMedication.clientMedicationId = clientMedicationId;
            this.modal.show();

        }
        else {

            this.selecteddocs = this.data.selecteddocs;
            this._discontinuedMedServiceProxy.getDiscontinuedMedForEdit(clientMedicationId).subscribe(result => {

                if (result.discontinuedMed != null) {
                    this.discontinueMedication = result.discontinuedMed;
                    this.docList = this.discontinueMedication.discontinueDocumentUploadInfo
                    this.getDocuments(this.discontinueMedication.id);
                }
                this.modal.show();

                this._clientMedicationServiceProxy.getClientMedicationForEdit(clientMedicationId).subscribe(result => {
                    this.clientMedication = result.clientMedication;
                    this.clientId = this.clientMedication.patientsId;
                })

            })

            if (this.data.sitedocobj) {
                // this.sitedocument = this.data.sitedocobj;
                this.discontinuedMedId = this.discontinuedocument.discontinuedMedId;
            }
            else if (this.data.event) {
                //  this.discontiDocView = this.data.event;
                let selectedDoc = new CreateOrEditDiscontinuedMedDocUploadDto();
                selectedDoc.id = this.data.event.id;
                selectedDoc.fileName = this.data.event.fileName;
                selectedDoc.patientsId = this.data.event.patientsId;


                // selectedDoc.documentType = this.siteDocView.sitedocument.documentType;
                selectedDoc.docDate = this.data.event.docDate;
                selectedDoc.description = this.data.event.description;
                // selectedDoc.patientsname = this.data.event.patientsname;

                selectedDoc.path = this.data.event.path;
                selectedDoc.status = this.data.event.status;
                selectedDoc.mimeType = this.data.event.mimeType;
                selectedDoc.size = this.data.event.size;
                // selectedDoc.c = this.data.event.creationTime;

                this.selecteddocs.push(selectedDoc);

                // this.DisplayClientName = this.data.event.patientsname;
                this.documenttype = this.data.event.documentType;
                //  this.docDate = this.data.event.docDate;
                //   this.selectedstatus = this.data.event.status;
                //  this.description = this.data.event.description;
                this.discontinuedMedId = this.data.event.discontinuedMedId;
                this.docId = this.data.event.id;
                this.editmode = true;
                this.clientId = this.data.event.patientsId;

            }
        }


    }
    onSelectPractition(event) { }
    searchPractition(event) { }

    onShown(): void {
        //
    }



    save(buttonType: string): void {

        if (buttonType == 'save') {
            this.noDocument = false;

            if (this.discontinueMedication.schedualTimeId == null || this.discontinueMedication.schedualTimeId == undefined || this.selecteddocs.length == 0 || this.selecteddocs == null || this.selecteddocs.length == undefined || this.discontinueMedication.discontinueReason == null || this.discontinueMedication.discontinueReason == undefined || this.discontinueMedication.discontinueDate == null || this.discontinueMedication.discontinueDate == undefined || this.discontinueMedication.discontinueDate == '' ||
                this.discontinueMedication.describeDiscReason == null || this.discontinueMedication.describeDiscReason == undefined || this.discontinueMedication.describeDiscReason == '') {
                this._required = true
            }

            else {

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    text: "Are you sure you want to save the form?",
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    width: '31rem',
                    heightAuto: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    title: ' This action cannot be undo',
                    icon: 'warning',
                }).then((result) => {
                    if (result.value) {
                        // this.discontinueMedication.clientMedicationId = this.medicationId;
                        this._discontinuedMedServiceProxy.createOrEdit(this.discontinueMedication)
                            .pipe(finalize(() => { }))
                            .subscribe(result => {
                                this.discontinuedMedId = result;
                                this.uploadDocuments();
                                if (this._mode == 'view') {
                                    this._router.navigate(['/app/medications/list']);
                                }
                                else {
                                    this.modal.hide();
                                }

                                this.notify.info(this.l('SavedSuccessfully'));

                                //  this.setcanDeactivate(true);
                                this.saveDiscontinoue.emit(this.discontinueMedication.clientMedicationId)

                            });
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {

                    }
                });
            }

        }
        // this._router.navigate(['/app/medications/list']);
    }

    close(): void {
        this._required = false;
        this.active = false;
        this.selecteddocs = null;
        this.discontinueMedication = new CreateOrEditDiscontinuedMedDto();
        this.modal.hide();
    }

    reasonSelect() {


    }
    public async onFileSelected(event: any) {
        this._required = false;
        this.selecteddocs = [];
        if (this.uploader) {
            for (let document of this.uploader.queue) {
                let selectedDoc = new CreateOrEditDiscontinuedMedDocUploadDto();
                selectedDoc.fileName = document.file.name;
                selectedDoc.patientsId = this.clientId;
                selectedDoc.documentType = this.documentId;
                //  selectedDoc.docDate = this.docDate;
                //selectedDoc.description = this.description;
                //selectedDoc.clientName = this.clientname;
                // selectedDoc.status = this.selectedstatus;
                this.selecteddocs.push(selectedDoc);
                this.isnewdocuploadded = true;
                this._view = true

            }
        }
    }

    getDocuments(discontinuedMedId) {

        this._DiscontinuedMedDocUploadsServiceProxy.getDiscontinuedMedDocUploadForEdit(discontinuedMedId)
            .subscribe(result => {
                this._view = true
                this.discontinuedocDto = result;
                if (this.discontinuedocDto.length == 0) {
                    this._view = false
                }
                this.docList = this.discontinuedocDto
                this.selecteddocs = this.docList;


            });


    }




    onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let error = JSON.parse(response); //error server response
        this.saving = false;
    }
    //for document upload
    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

        let data = JSON.parse(response);
        let documentobj = new CreateOrEditDiscontinuedMedDocUploadDto();
        documentobj = data.result;

        this.selecteddocs[0].size = documentobj.size;
        this.selecteddocs[0].path = documentobj.path;
        this.selecteddocs[0].mimeType = documentobj.mimeType;
        this.selecteddocs[0].fileName = documentobj.fileName;


        this.saving = true;
        this.discontinuedocument = this.selecteddocs[0];
        // this.discontinuedocument.docDate = this.docDate;       

        this.discontinuedocument.patientsId = this.clientId;
        this.discontinuedocument.documentType = this.documenttype;
        // this.discontinuedocument.description = this.description; 
        this.discontinuedocument.discontinuedMedId = this.discontinuedMedId;
        // if (this.selectedstatus)
        //     this.discontinuedocument.status = this.selectedstatus;
        if (!this.discontinuedocument.path) {
            this.message.error("Please Browse document.");
            this.saving = false;
            return;
        }
        const controls = this.form.controls;
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            this.saving = false;
            return;
        }

        this.discontinuedocument.patientsId = this.clientId;
        this._DiscontinuedMedDocUploadsServiceProxy.createOrEdit(this.discontinuedocument)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe((result) => {
                //  this.dialogRef.close({ data: 'success' });
                this.notify.info(this.l('Uploaded Successfully.'));
            });


    }

    deleteDocument(i, item: CreateOrEditDiscontinuedMedDocUploadDto) {
        this.message.confirm(" ", 'Are you sure you want to delete the document(' + item.fileName + ') ?',
            (isConfirmed) => {
                if (isConfirmed) {
                    let index = this.selecteddocs.findIndex(s => s.fileName == item.fileName);
                    if (index > -1) {
                        this.selecteddocs.splice(i, 1);
                        var findFile = this.uploader.queue.find(s => s.file.name == item.fileName);
                        this.uploader.removeFromQueue(findFile);
                    }
                }
            }
        );
    }

    viewdoc(item, path) {
        if (this.status == 'Approved') {
            var data = this.uploader.queue.filter(s => s.file.name == item);
            let fileCount: number = data.length;
            if (fileCount > 0) {
                data.forEach((val, i, array) => {
                    let fileReader = new FileReader();
                    fileReader.onloadend = (e) => {
                        let imageData = fileReader.result;
                        let rawData = imageData.toString().split("base64,");
                        if (rawData.length > 1) {
                            let base64 = rawData[1];
                            const byteCharacters = atob(base64);
                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }

                            const byteArray = new Uint8Array(byteNumbers);
                            const blob = new Blob([byteArray], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            window.open(url, '_blank');
                        }
                    }

                    fileReader.readAsDataURL(val._file);
                });
            }

        } else {
            window.open(path, '_blank');
        }
    }

    async uploadDocuments() {

        this.saving = true;
        const controls = this.form.controls;
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            // this.saving = false;
            return;
        }
        if (!this.selecteddocs)
            this.selecteddocs = [];
        if (this.editmode && this.isnewdocuploadded == false && this.selecteddocs.length > 0) {
            this.saving = true;
            this.discontinuedocument = this.selecteddocs[0];
            //  this.discontinuedocument.docDate = this.docDate;       

            this.discontinuedocument.patientsId = this.clientId;
            this.discontinuedocument.documentType = this.documenttype;
            //  this.discontinuedocument.description = this.description; 
            this.discontinuedocument.discontinuedMedId = this.discontinuedMedId;
            // if (this.selectedstatus)
            //     this.discontinuedocument.status = this.selectedstatus;
            if (!this.discontinuedocument.path) {
                this.message.error("Please Browse document.");
                this.saving = false;
                return;
            }
            const controls = this.form.controls;
            if (this.form.invalid) {
                Object.keys(controls).forEach(controlName =>
                    controls[controlName].markAsTouched()
                );
                this.saving = false;
                return;
            }

            this.discontinuedocument.id = this.docId;
            this._DiscontinuedMedDocUploadsServiceProxy.createOrEdit(this.discontinuedocument)
                .pipe(finalize(() => { this.saving = false; }))
                .subscribe((result) => {
                    // this.dialogRef.close({ data: 'success' });
                    this.notify.info(this.l('Uploaded Successfully.'));
                });
        }
        else {
            if (this.uploader.queue.length == 0) {
                this.saving = false;
                this.message.error("Please Browse document.")
            }
            for (let document of this.selecteddocs) {
                document.documentType = this.documentId
                //  document.description = this.description
                // document.docDate = this.docDate
                document.patientsId = this.clientId;
                // if (this.selectedstatus)
                //     document.status = this.selectedstatus;
                var doc = JSON.stringify(document);
                var file = this.uploader.queue.find(s => s.file.name == document.fileName);

                if (file) {
                    file.headers = [{ name: 'email', value: this.appSession.user.emailAddress },
                    { name: 'Document', value: doc },
                    { 'Authorization': 'Bearer ' + abp.auth.getToken() }];
                    await file.upload();


                    const uploaderOptions: FileUploaderOptions = {};
                    uploaderOptions.authToken = 'Bearer ' + abp.auth.getToken();
                    uploaderOptions.headers = [{ name: 'email', value: this.appSession.user.emailAddress }
                        , { name: 'Document', value: doc }];
                    this.uploader.onAfterAddingFile = () => { file.withCredentials = false; };
                    file.withCredentials = false;
                    this.uploader.setOptions(uploaderOptions);
                    await this.uploader.uploadItem(file);

                }
            }
        }
    }
    // getDocuments() {

    //     this._sitedocsServiceProxy.getAll(this.clientMedication.id)
    //         .subscribe(result => {

    //             this.selecteddocs = result;
    //             if (!this.selecteddocs)
    //                 this.selecteddocs = [];
    //             if (this.selecteddocs != null && this.selecteddocs != undefined)
    //               //  this.doclistlength.emit(this.selecteddocs.length);

    //         });


    // }
}
